import React from 'react'

export const Footer = () => {
    return (
        <footer style={{ position:'fixed',bottom:0,left:0,right:0}}>
            <div className="footer-area">
                {/* <p>© Copyright 2021. All right reserved. Template by <b>Plantilla ROBMAX</b></p> */}
            </div>
        </footer>
    )
}


