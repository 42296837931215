

export const  types = {

    login:'[auth] Login',
    logout:'[auth] Logout',

    preload:'[preload] Change state preload (true or false)',

    superadmin: 3,
    admin: 2,
    estandar: 1,

    changeImg: '[perfil] change image'

}